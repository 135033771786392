import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
// import starsVertexShader from './shaders/stars/vertex.glsl'
// import starsFragmentShader from './shaders/stars/fragment.glsl'
// import tuyauVertexShader from './shaders/tuyau/vertex.glsl'
// import tuyauFragmentShader from './shaders/tuyau/fragment.glsl'
// import portalVertexShader from './shaders/portal/vertex.glsl'
// import portalFragmentShader from './shaders/portal/fragment.glsl'
// import screenVertexShader from './shaders/screens/vertex.glsl'
// import screenFragmentShader from './shaders/screens/fragment.glsl'
import { gsap } from 'gsap'
// console.log('JavaScript is working', THREE)

import galaxyVertexShader from './shaders/galaxy/vertex.glsl'
import galaxyFragmentShader from './shaders/galaxy/fragment.glsl'

let users = [];

// Fetch users from server
// fetch('/json/users.json')
//     .then(response => response.json())
//     .then(data => {
//         users = data;
//         console.log(users);
//     });

// document.getElementById('loginForm').addEventListener('submit', function (e) {
//     e.preventDefault();

//     const username = document.getElementById('username').value;
//     const password = document.getElementById('password').value;
//     const message = document.getElementById('message');

//     const user = users.find(u => u.username === username && u.password === password);

//     if (user) {
//         message.textContent = 'Access granted. Initializing 3D environment...';
//         message.style.color = '#0f0';
//         document.getElementById('modifyAccount').style.display = 'block';
//         document.getElementById('deleteAccount').style.display = 'block';
//         // Initialize Three.js environment
//         initThreeJS(username);
//     } else {
//         message.textContent = 'Access denied. Invalid credentials.';
//         message.style.color = '#f00';
//     }
// });

// document.addEventListener('DOMContentLoaded', function () {
//     const rememberMeCheckbox = document.getElementById('rememberMe');

//     // Load the rememberMe state from local storage
//     const rememberMeState = localStorage.getItem('rememberMe') === 'true';
//     rememberMeCheckbox.checked = rememberMeState;

//     // Add event listener to rememberMe checkbox
//     rememberMeCheckbox.addEventListener('change', function () {
//         localStorage.setItem('rememberMe', rememberMeCheckbox.checked);
//     });

//     // Handle login form submission
//     document.getElementById('loginForm').addEventListener('submit', function (event) {
//         event.preventDefault();
//         const username = document.getElementById('username').value;
//         const password = document.getElementById('password').value;
//         const rememberMe = rememberMeCheckbox.checked;

//         // Perform login logic here
//         fetch('/json/users.json', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ username, password, rememberMe }),
//         })
//             .then(response => response.json())
//             .then(data => {
//                 if (data.error) {
//                     document.getElementById('message').textContent = data.error;
//                 } else {
//                     document.getElementById('message').textContent = 'Login successful.';
//                     // Handle successful login
//                     // Initialize the Three.js scene
//                     initThreeJS(username);
//                 }
//             });
//     });
// });

document.querySelector('#loginForm').addEventListener('submit', function (event) {
    event.preventDefault();
    const username = document.querySelector('#username').value;
    const password = document.querySelector('#password').value;
    const message = document.getElementById('message');

    fetch('/json/users.json')
        .then(response => response.json())
        .then(data => {
            const users = data.users;
            // console.log('Type of users:', typeof users);
            // console.log('Is users an array?', Array.isArray(users));
            const user = users.find(user => user.username === username && user.password === password);
            if (user) {
                // console.log('User found:', user);
                document.querySelector('.login-container').style.display = 'none';

                // Show the canvas
                document.querySelector('.webgl').style.display = 'block';

                // Initialize the Three.js scene
                initThreeJS(username);
            } else {
                console.log('User not found');
                message.textContent = 'Invalid username or password';
                message.style.color = 'red';
            }
        })
        .catch(error => console.error('Error:', error));
});

// document.getElementById('registerForm').addEventListener('submit', async function (e) {
//     e.preventDefault();

//     const username = document.getElementById('newUsername').value;
//     const password = document.getElementById('newPassword').value;

//     try {
//         const response = await fetch('/json/users.json', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ username, password })
//         });

//         const result = await response.json();
//         if (result.error) {
//             alert(result.error);
//         } else {
//             alert('Registration successful');
//         }
//     } catch (error) {
//         console.error('Error:', error);
//     }
// });
// document.querySelector('#loginForm').addEventListener('submit', function (event) {
//     event.preventDefault();
//     const username = document.querySelector('#username').value;
//     const password = document.querySelector('#password').value;
//     const message = document.getElementById('message');

//     fetch('/login', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ username, password }),
//     })
//         .then(response => response.json())
//         .then(data => {
//             if (data.success) {
//                 document.querySelector('.login-container').style.display = 'none';
//                 document.querySelector('.webgl').style.display = 'block';
//                 initThreeJS(username);
//             } else {
//                 message.textContent = 'Invalid username or password';
//                 message.style.color = 'red';
//             }
//         })
//         .catch(error => console.error('Error:', error));
// });

// document.getElementById('createAccount').addEventListener('click', function () {
//     document.getElementById('usernameForm').style.display = 'block';
//     document.getElementById('passwordForm').style.display = 'block';
// });
// document.getElementById('createAccountForm').addEventListener('submit', function (event) {
//     const username = document.getElementById('usernameForm').value;
//     const password = document.getElementById('passwordForm').value;
//     const errorMessage = document.getElementById('error-message');

//     if (!username || !password) {
//         event.preventDefault();
//         errorMessage.style.display = 'block';
//     } else {
//         errorMessage.style.display = 'none';
//     }
// });

document.getElementById('createAccount').addEventListener('click', function () {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    fetch('/json/users.json', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
    })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                document.getElementById('message').textContent = data.error;
            } else {
                document.getElementById('message').textContent = 'Account created successfully.';
                // Assuming `users` is a global variable or fetched from the server
                users.push(data);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            document.getElementById('message').textContent = 'An error occurred while creating the account.';
        });
});


document.getElementById('modifyAccount').addEventListener('click', function () {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    fetch('/json/users.json', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
    })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                document.getElementById('message').textContent = data.error;
            } else {
                document.getElementById('message').textContent = 'Account modified successfully.';
                // Update the local users array if necessary
                const userIndex = users.findIndex(user => user.username === username);
                if (userIndex !== -1) {
                    users[userIndex] = data;
                }
            }
        });
});
// document.getElementById('modifyAccount').addEventListener('click', function () {
//     const username = document.getElementById('username').value;
//     const newPassword = prompt('Enter new password:');

//     fetch(`/json/users/${username}`, {
//         method: 'PUT',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ password: newPassword }),
//     })
//         .then(response => response.json())
//         .then(data => {
//             if (data.error) {
//                 document.getElementById('message').textContent = data.error;
//             } else {
//                 document.getElementById('message').textContent = 'Account modified successfully.';
//                 const userIndex = users.findIndex(u => u.username === username);
//                 if (userIndex !== -1) {
//                     users[userIndex] = data;
//                 }
//             }
//         });
// });

document.getElementById('deleteAccount').addEventListener('click', function () {
    const username = document.getElementById('username').value;

    fetch(`/json/users/${username}`, {
        method: 'DELETE',
    })
        .then(response => {
            if (response.status === 204) {
                document.getElementById('message').textContent = 'Account deleted successfully.';
                users = users.filter(u => u.username !== username);
            } else {
                document.getElementById('message').textContent = 'Error deleting account.';
            }
        });
});

function initThreeJS(username) {

    THREE.ColorManagement.enabled = false

    let sceneReady = false

    const loadingUnit = document.querySelector('.modalUnit')
    loadingUnit.style.display = 'block'
    const loadingPercentElement = document.querySelector('.modal-percent')
    const loadingBarElement = document.querySelector('.loading-bar')
    const loadingDivElement = document.querySelector('#loading')
    const loadingTextElement = document.querySelector('.loading-text')
    let songmetas = document.getElementById('songmetas')
    /**
     * Base
     */
    // Debug
    const gui = new dat.GUI()

    // Canvas
    const canvas = document.querySelector('canvas.webgl')

    // Scene
    const scene = new THREE.Scene()
    const loadingManager = new THREE.LoadingManager(
        // Loaded
        () => {
            gsap.delayedCall(0.5, () => {
                gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 4, value: 1 })
                loadingBarElement.classList.add('ended')
                loadingBarElement.style.transform = ''
                loadingTextElement.innerHTML = `Loading file : All assets is loaded`
                window.setTimeout(() => {
                    loadingTextElement.style.opacity = '0'
                    loadingDivElement.style.opacity = '0'
                    loadingPercentElement.style.opacity = '0'
                    window.setTimeout(() => {
                        loadingPercentElement.style.display = 'none'
                        loadingBarElement.style.display = 'none'
                        loadingTextElement.style.display = 'none'
                        loadingDivElement.style.display = 'none'
                        // loadingPercentElement.remove();
                        // loadingBarElement.remove();
                        // loadingTextElement.remove();
                        // loadingDivElement.remove();

                    }, 100)
                }, 3500)
            })
            window.setTimeout(() => {
                gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 5, value: 0 })
                loadingBarElement.classList.add('ended')
                loadingBarElement.style.transform = ''
            }, 500)
            window.setTimeout(() => {
                sceneReady = true
                loadingTextElement.style.opacity = '0'
            }, 4000)
        },
        // Progress
        (itemUrl, itemsLoaded, itemsTotal) => {
            // console.log(itemUrl, itemsLoaded / itemsTotal)
            // console.log(itemUrl, (itemsLoaded, itemsTotal)
            const progressPercent = Math.floor((itemsLoaded / itemsTotal) * 100)
            const progressRatio = itemsLoaded / itemsTotal
            // To keep only the name of asset
            const progressText = itemUrl.split("/").slice(-1).join().split(".").shift()
            // const progressText = itemUrl.split('.').slice(0, -1).join('.')
            // const progressText = itemUrl.replace(/\.[^.]*$/, '')
            loadingPercentElement.innerHTML = `${progressPercent}%`
            loadingTextElement.innerHTML = `Loading file : ${progressText} </br> Total of files: ${itemsLoaded}`
            loadingBarElement.style.transform = `scaleX(${progressRatio})`
            // console.log(progressRatio)
        }
    )


    /**
     * Overlay
     */

    const overlayGeometry = new THREE.PlaneGeometry(2, 2)
    const overlayMaterial = new THREE.ShaderMaterial({
        // wireframe: true,
        transparent: true,
        uniforms:
        {
            uAlpha: { value: 1.0 }
        },
        vertexShader: `
    void main()
    {
        gl_Position = vec4(position, 1.0);
    }
    `,
        fragmentShader: `
    uniform float uAlpha;
    void main()
    {
        gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
    }
    `
    })
    const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
    scene.add(overlay)

    /**
     * Galaxy
     */
    const parameters = {}
    parameters.count = 200000
    parameters.size = 0.005
    parameters.radius = 5
    parameters.branches = 3
    parameters.spin = 1
    parameters.randomness = 0.2
    parameters.randomnessPower = 3
    parameters.insideColor = '#ff6030'
    parameters.outsideColor = '#1b3984'

    let geometry = null
    let material = null
    let points = null

    const generateGalaxy = () => {
        if (points !== null) {
            geometry.dispose()
            material.dispose()
            scene.remove(points)
        }

        /**
         * Geometry
         */
        geometry = new THREE.BufferGeometry()

        const positions = new Float32Array(parameters.count * 3)
        const randomness = new Float32Array(parameters.count * 3)
        const colors = new Float32Array(parameters.count * 3)
        const scales = new Float32Array(parameters.count * 1)

        const insideColor = new THREE.Color(parameters.insideColor)
        const outsideColor = new THREE.Color(parameters.outsideColor)

        for (let i = 0; i < parameters.count; i++) {
            const i3 = i * 3

            // Position
            const radius = Math.random() * parameters.radius

            const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2

            const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
            const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
            const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius

            positions[i3] = Math.cos(branchAngle) * radius
            positions[i3 + 1] = 0
            positions[i3 + 2] = Math.sin(branchAngle) * radius

            randomness[i3] = randomX
            randomness[i3 + 1] = randomY
            randomness[i3 + 2] = randomZ

            // Color
            const mixedColor = insideColor.clone()
            mixedColor.lerp(outsideColor, radius / parameters.radius)

            colors[i3] = mixedColor.r
            colors[i3 + 1] = mixedColor.g
            colors[i3 + 2] = mixedColor.b

            // Scale
            scales[i] = Math.random()
        }

        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        geometry.setAttribute('aRandomness', new THREE.BufferAttribute(randomness, 3))
        geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
        geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))

        /**
         * Material
         */
        material = new THREE.ShaderMaterial({
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            uniforms:
            {
                uTime: { value: 0 },
                uSize: { value: 30 * renderer.getPixelRatio() }
            },
            vertexShader: galaxyVertexShader,
            fragmentShader: galaxyFragmentShader
        })

        // Create a group
        const group = new THREE.Group()
        /**
         * Points
         */
        points = new THREE.Points(geometry, material)
        group.add(points)
        // group.scale.set(0.1, 0.1, 0.1)
        group.position.z = - 0.5
        scene.add(group)
    }

    gui.add(parameters, 'count').min(100).max(1000000).step(100).onFinishChange(generateGalaxy)
    gui.add(parameters, 'radius').min(0.01).max(20).step(0.01).onFinishChange(generateGalaxy)
    gui.add(parameters, 'branches').min(2).max(20).step(1).onFinishChange(generateGalaxy)
    gui.add(parameters, 'randomness').min(0).max(2).step(0.001).onFinishChange(generateGalaxy)
    gui.add(parameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(generateGalaxy)
    gui.addColor(parameters, 'insideColor').onFinishChange(generateGalaxy)
    gui.addColor(parameters, 'outsideColor').onFinishChange(generateGalaxy)
    gui.hide()

    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    window.addEventListener('resize', () => {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000)
    camera.position.set(0, 0.5, 4)
    scene.add(camera)

    // Controls
    const controls = new OrbitControls(camera, canvas)
    controls.enableDamping = true



    // Create an AudioListener and add it to the camera
    const listener = new THREE.AudioListener();
    camera.add(listener);

    // Create a global audio source
    const sound = new THREE.Audio(listener);

    // Load a sound and set it as the Audio object's buffer
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load('/musics/Sphinx Solitude.mp3', function (buffer) {
        sound.setBuffer(buffer);
        sound.setLoop(true);
        sound.setVolume(0.5);
        sound.play();
        console.log(sound)
        console.log(audioLoader)
    });
    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
    })
    renderer.outputColorSpace = THREE.LinearSRGBColorSpace
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    /**
     * Generate the first galaxy
     */
    generateGalaxy()

    /**
     * Loaders
     */
    // Texture loader
    const textureLoader = new THREE.TextureLoader(loadingManager)


    /**
     * Textures
     */
    const matcap3Texture = textureLoader.load('/textures/matcaps/3.png')
    const matcap5Texture = textureLoader.load('/textures/matcaps/8.png')
    // const bakedTexture = textureLoader.load('baked_256-hdr.jpg')
    let stars = []
    /**
     * Fonts
     */

    const fontLoader = new FontLoader()

    fontLoader.load(
        '/fonts/helvetiker_regular.typeface.json',
        (font) => {
            // console.log('loaded')
            const textGeometry0 = new TextGeometry(
                'Planet-A Project',
                {
                    font: font,
                    size: 0.275,
                    depth: 0.0125,
                    curveSegments: 8,
                    bevelEnabled: true,
                    bevelThickness: 0.02,
                    bevelSize: 0.005,
                    bevelOffset: 0,
                    bevelSegments: 4
                }
            )
            const textGeometry1 = new TextGeometry(
                'R E B E L S',
                {
                    font: font,
                    size: 0.0875,
                    depth: 0.0125,
                    curveSegments: 8,
                    bevelEnabled: true,
                    bevelThickness: 0.02,
                    bevelSize: 0.005,
                    bevelOffset: 0,
                    bevelSegments: 4
                }
            )
            const textGeometry2 = new TextGeometry(
                'o f   t h e',
                {
                    font: font,
                    size: 0.0725,
                    depth: 0.0125,
                    curveSegments: 8,
                    bevelEnabled: true,
                    bevelThickness: 0.02,
                    bevelSize: 0.005,
                    bevelOffset: 0,
                    bevelSegments: 4
                }
            )
            const textGeometry3 = new TextGeometry(
                'S K Y',
                {
                    font: font,
                    size: 0.135,
                    depth: 0.0125,
                    curveSegments: 8,
                    bevelEnabled: true,
                    bevelThickness: 0.02,
                    bevelSize: 0.005,
                    bevelOffset: 0,
                    bevelSegments: 8
                }
            )
            const textGeometry4 = new TextGeometry(
                'User connected : Welcome ' + username + ' !',
                {
                    font: font,
                    size: 0.055,
                    depth: 0.0125,
                    curveSegments: 8,
                    bevelEnabled: true,
                    bevelThickness: 0.01,
                    bevelSize: 0.0025,
                    bevelOffset: 0,
                    bevelSegments: 8
                }
            )
            const textGeometry5 = new TextGeometry(
                'P R O D U C T I O N S   F E N Y X',
                {
                    font: font,
                    size: 0.02875,
                    depth: 0.0125,
                    curveSegments: 16,
                    bevelEnabled: true,
                    bevelThickness: 0.01,
                    bevelSize: 0.0025,
                    bevelOffset: 0,
                    bevelSegments: 8
                }
            )

            // Old center
            // textGeometry.computeBoundingBox()
            // textGeometry.translate(
            //     - (textGeometry.boundingBox.max.x - 0.02) * 0.5,
            //     - (textGeometry.boundingBox.max.y - 0.02) * 0.5,
            //     - (textGeometry.boundingBox.max.z - 0.03) * 0.5
            // )

            textGeometry0.center()
            textGeometry1.center()
            textGeometry2.center()
            textGeometry3.center()
            textGeometry4.center()
            textGeometry5.center()
            // 

            // console.log(textGeometry)
            // console.log(textGeometry.boundingBox)
            const material = new THREE.MeshMatcapMaterial({ matcap: matcap3Texture })
            const material8 = new THREE.MeshMatcapMaterial({ matcap: matcap5Texture })
            // textMaterial.matcap = matcapTexture
            const text0 = new THREE.Mesh(textGeometry0, material)
            const text1 = new THREE.Mesh(textGeometry1, material)
            const text2 = new THREE.Mesh(textGeometry2, material)
            const text3 = new THREE.Mesh(textGeometry3, material)
            const text4 = new THREE.Mesh(textGeometry4, material)
            const text5 = new THREE.Mesh(textGeometry5, material)

            text0.position.x = 0
            text0.position.y = 2.0
            text0.position.z = 0

            text1.position.y = 1.35
            text2.position.y = 1.22
            text3.position.y = 1.075
            text4.position.y = 0.93
            text5.position.y = 0.85

            const groupLabel = new THREE.Group()

            // groupLabel.add(text, text2, text3, text4, text5)
            groupLabel.add(text1, text2, text3, text4, text5)
            groupLabel.position.y = - 1.4
            groupLabel.scale.set(2.25, 2.25, 2.25)
            // groupLabel.position.y = - 0.35
            groupLabel.position.z = 0
            // groupLabel.position.z = - 2.05
            // groupLabel.rotation.y = Math.PI * 1

            scene.add(groupLabel)

            scene.add(text0)

        }
    )

    // Stars version OK
    const starGeometry = new THREE.PlaneGeometry(0.5, 0.5)

    const starTexture = new THREE.TextureLoader().load('./stars/spikey.png')
    starTexture.colorSpace = THREE.SRGBColorSpace
    const starTextureMaterial = new THREE.MeshBasicMaterial({ map: starTexture, transparent: true, opacity: 1, side: THREE.DoubleSide, alphaTest: 0.05, depthTest: false })

    for (let i = 0; i < 1000; i++) {

        let star = new THREE.Mesh(starGeometry, starTextureMaterial)

        star.position.x = (Math.random() - 0.5) * 125
        star.position.y = (Math.random() - 0.5) * 125
        star.position.z = (Math.random() - 0.5) * 125
        star.rotation.x = Math.random() * Math.PI
        star.rotation.y = Math.random() * Math.PI
        const scale = Math.random()
        star.scale.set(scale, scale, scale)
        // star.lookAt(camera.position)
        stars.push(star)
        scene.add(star)

    }


    /**
     * Animate
     */
    const clock = new THREE.Clock()

    const tick = () => {
        const elapsedTime = clock.getElapsedTime()

        // Update material
        material.uniforms.uTime.value = elapsedTime

        // Update controls
        controls.update()

        // Render
        renderer.render(scene, camera)

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()

    // generateGalaxy();
}